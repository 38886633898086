<template>
  <!-- 缴费 -->
  <div class="pay">
    <!-- 切换就诊人 -->
    <SwitchPeople style="margin-bottom:1rem" />
    <!-- 切换待支付和历史记录 -->
    <van-tabs v-model="active" @change="showClick" animated>
      <van-tab v-for="(item, index) in tabs" :key="index" :title="item">
        <!-- 空状态 -->
        <div v-if="active == 0 && data.list.length == 0">
          <van-empty description="无缴费记录" />
        </div>
        <Scroll
          class="region-scroll"
          ref="scroll"
          @pullingUp="scrollBottom"
          :pullUpLoad="true"
        >
          <van-list
            v-model="loading"
            :finished="active == 0 ? finishedPaid : finishedRecord"
            :finished-text="active == 0 ? textdPaid : textRecord"
            :error.sync="error"
          >
            <div
              class="line"
              v-if="
                active == 0 ? data.list.length != 0 : payList.list.length != 0
              "
            >
              <van-checkbox @click="checkAll" v-model="checked">
                全部缴费
              </van-checkbox>
              <van-button
                class="handle-button"
                type="primary"
                block
                @click="payClick(active)"
              >
                缴费
              </van-button>
            </div>
            <van-checkbox-group
              v-model="result"
              ref="checkboxGroup"
              @change="change"
            >
              <div
                v-for="(item, index) in active == 0 ? data.list : payList.list"
                :key="item.rctp_no"
              >
                <!-- 待支付 -->
                <div class="card" v-show="active == 0">
                  <ul class="card-text">
                    <van-checkbox :name="index">
                      {{ item.itemclass }}
                    </van-checkbox>
                    <li>
                      {{ Math.floor(item.charge_amt * 100) / 100 + '元' }}
                    </li>
                  </ul>
                  <ul class="card-text">
                    <li class="time">{{ item.visit_date }}</li>
                    <li class="detailed" @click="detailed(active, item)">
                      查看明细
                    </li>
                  </ul>
                </div>
                <!-- 历史记录 -->
                <div class="card" v-show="active == 1">
                  <ul class="card-text">
                    <li>时间</li>
                    <li>{{ item.time }}</li>
                  </ul>
                  <ul class="card-text">
                    <li>{{ item.businessType }}</li>
                    <li style="color:rgb(141, 143, 161)">
                      {{ Math.floor(item.selfMoney * 100) / 100 + '元' }}
                    </li>
                  </ul>
                </div>
              </div>
            </van-checkbox-group>
          </van-list>
        </Scroll>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import { patientPayInfo, businessHistoryQuery } from '@/network/service'
import { qrcode, loopQuery } from '@/network/payment'
import { saveTradeLog, updateLogFunTrade } from '@/network/service'
import Scroll from '@/components/content/scroll/BScroll'
import { times, Debounce } from '@/common/utils'
import { mapState } from 'vuex'
export default {
  name: 'pay',
  components: {
    SwitchPeople,
    Scroll,
  },
  data() {
    return {
      tabs: ['待支付'],
      //当前选择
      active: 0,
      //缴费信息查询
      data: {
        page: 1,
        list: [],
      },
      checked: false,
      result: [],
      //历史信息查询
      payList: {
        page: 1,
        list: [],
      },
      //等待
      loading: false,
      //待支付
      valvePaid: true, //上拉加载
      finishedPaid: false,
      textdPaid: '没有更多了',
      //历史记录
      valveRecord: true, //上拉加载
      finishedRecord: false,
      textRecord: '没有更多了',
      //请求一次
      once: true,
      //错误
      error: false,
    }
  },
  mounted() {
    //缴费信息
    this.patientPayInfoPost(this.active)
  },
  computed: {
    ...mapState(['seq', 'cardList']),
  },
  methods: {
    //缴费信息
    async patientPayInfoPost(i) {
      try {
        let res = await patientPayInfo({
          med_id: this.$store.state.med_id,
          card_no: this.cardList[0].cardNo,
          card_type: this.cardList[0].cardType,
        })
        //卡号过滤
        // res.data.root.body.detail = res.data.root.body.detail.filter(
        //   item => item.cardNo !== this.$store.state.cardList[0].cardNo
        // );
        let le = res.data.root.body.detail.length
        if (res == undefined) {
          this.textdPaid = ''
          this.finishedPaid = true
          this.valvePaid = false
          this.loading = false
        } else if (res.code == '0') {
          if (this.data.page == 1 && le == 0) {
            //第一次加载没数据
            this.textdPaid = ''
            this.finishedPaid = true
            this.valvePaid = false
          } else if (this.data.page == 1 && le < this.$store.state.pageSet) {
            //第一次加载数少于10个
            this.data.list = [...res.data.root.body.detail]
            this.valvePaid = false
            this.finishedPaid = true
            this.textdPaid = ''
            this.$nextTick(() => {
              this.$refs.scroll[i].refresh()
            })
          } else {
            //添加信息
            this.data.list.push(...res.data.root.body.detail)
            //上拉加载一页
            this.data.page += 1
            //上拉加载更多
            this.$refs.scroll[i].finishPullUp()
            if (le == 0) {
              this.valvePaid = false
              this.finishedPaid = true
              this.textdPaid = '没有更多了'
            }
          }
        }
      } catch {
        this.loading = false
        this.error = true
      }
    },
    checkAll() {
      if (this.checked == true) {
        this.$refs.checkboxGroup.forEach(item => item.toggleAll(true))
      } else {
        this.$refs.checkboxGroup.forEach(item => item.toggleAll(false))
      }
    },
    change() {
      if (this.result.length == this.data.list.length) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    //历史记录
    async businessHistoryQueryPost(i) {
      try {
        let res = await businessHistoryQuery({
          open_id: '11',
          business_type: '缴费',
          begin_date: '2000-01-01',
          end_date: times().slice(0, 10),
          page_no: this.payList.page,
          page_size: this.$store.state.pageSet,
        })
        //卡号过滤
        // res.data.content = res.data.content.filter(
        //   item => item.cardNo !== this.$store.state.cardList[0].cardNo
        // );
        let le = res.data.content.length
        if (res == undefined) {
          this.textRecord = ''
          this.finishedRecord = true
          this.valveRecord = false
          this.loading = false
        } else if (res.code == '0') {
          if (this.payList.page == 1 && le == 0) {
            //第一次加载没数据
            this.textRecord = ''
            this.finishedRecord = true
            this.valveRecord = false
          } else if (this.payList.page == 1 && le < this.$store.state.pageSet) {
            //第一次加载数少于10个
            this.payList.list = [...res.data.content]
            this.valveRecord = false
            this.finishedRecord = true
            this.textRecord = ''
          } else {
            //添加信息
            this.payList.list.push(...res.data.content)
            //上拉加载一页
            this.payList.page += 1
            //上拉加载更多
            this.$refs.scroll[i].finishPullUp()
            if (res.data.content.length == 0) {
              this.valveRecord = false
              this.finishedRecord = true
              this.textRecord = '没有更多了'
            }
          }
        }
      } catch {
        this.loading = false
        this.error = true
      }
      this.$nextTick(() => {
        this.$refs.scroll[i].refresh()
      })
    },
    //缴费
    payClick: Debounce(async function payClick(active) {
      if (active == 0) {
        if (this.result.length == 0) {
          this.$toast('请选择需要缴费的处方')
          return
        }
        let paylist = this.result.map(el => this.data.list[el])
        let total_charge_amt = paylist.reduce(function(
          accumulator,
          currentValue
        ) {
          return accumulator + Number(currentValue.charge_amt)
        },
        0)
        let chargeId = paylist.reduce(function(accumulator, currentValue) {
          return accumulator + currentValue.charge_id + ','
        }, 0)
        console.log(Math.floor(total_charge_amt * 100) / 100)
        let charge_id = chargeId.slice(1, chargeId.length - 1)
        let res = await qrcode({
          seq: this.seq,
          urlString: this.$urlString,
          appKey: '17b1a89be880001',
          appSecret: '33cb874e10bb409a84f71d29f49ca017',
          mchNo: 'lffbyy',
          billNo: this.cardList[0].openId + new Date().getTime(),
          accountId: '105000080629028',
          sceneId: 'zyzf',
          totalAmount: parseInt(total_charge_amt.toFixed(2) * 100),
          subject: '缴费',
          // appKey: '177146cb8f80001',
          // appSecret: '4fdb3405f7cc4554bd76ebd306d33fc5',
          // mchNo: 'jandar',
          // billNo: this.cardList[0].openId + new Date().getTime(),
          // accountId: '105000080628641 ',
          // sceneId: 'TEST',
          // totalAmount: "1",
          // subject: '缴费'
        })
        if (res.code == '0') {
          // let y = new Date().getFullYear()
          let m = new Date().getMonth() + 1
          m = m < 10 ? '0' + m : m
          let d = new Date().getDate()
          d = d < 10 ? '0' + d : d
          let date = m + d
          //更新流水号
          await updateLogFunTrade({
            seq: this.seq,
            finishFlag: '缴费',
            card_type: this.cardList[0].cardType,
            card_no: this.cardList[0].cardNo,
            idno: this.cardList[0].idNo,
            name: this.cardList[0].name,
          })
          //保存支付总订单表日志记录
          await saveTradeLog({
            seq: this.seq,
            stat: 'PAYING',
            total_charge_amt: total_charge_amt.toFixed(2),
            person_pay: total_charge_amt.toFixed(2),
            channel: '建行聚合支付',
            trade_no: res.data.trade_no,
            med_pay: '1',
            business_type: '缴费',
          })
          //下单轮询
          let outcome = await loopQuery({
            seq: this.seq,
            urlString: this.$urlString,
            mchNo: 'lffbyy',
            appKey: '17b1a89be880001',
            appSecret: '33cb874e10bb409a84f71d29f49ca017',
            trade_no: res.data.trade_no,
            tradeNo: res.data.trade_no,
            card_no: this.cardList[0].cardNo,
            card_type: this.cardList[0].cardType,
            total_charge_amt: total_charge_amt.toFixed(2),
            person_pay: total_charge_amt.toFixed(2),
            charge_id: charge_id,
            payway: '0',
            source: '缴费',
            bankno: res.data.trade_no,
            bankcard_info: date + '|' + this.$store.state.med_id,
            med_id: this.$store.state.med_id,
            doc_name: paylist[0].doc_name,
            treat_date: times(),
            name: this.cardList[0].name,
            refundReason: '业务失败退还',
            // mchNo: 'jandar',
            // appKey: '177146cb8f80001',
            // appSecret: '4fdb3405f7cc4554bd76ebd306d33fc5',
            // tradeNo: res.data.trade_no,
            // card_no: this.cardList[0].cardNo,
            // card_type: this.cardList[0].cardType,
            // total_charge_amt: parseInt(total_charge_amt.toFixed(2) * 100),
            // person_pay: parseInt(total_charge_amt.toFixed(2) * 100),
            // payway: '0',
            // source: '缴费'
          })
          if (outcome.code == 0) {
            //调用下单页面
            window.location.href = res.data.codeUrl
          } else {
            this.$toast(res.msg)
          }
        } else {
          this.$toast(res.msg)
        }
        // } else {
        //   this.$toast(data.msg)
        // }
      }
    }, 1500),
    //上拉加载更多
    scrollBottom() {
      if (
        this.active == 1 &&
        this.valveRecord &&
        this.payList.list.length >= this.$store.state.pageSet
      ) {
        this.businessHistoryQueryPost(this.active)
      } else if (
        this.active == 0 &&
        this.valvePaid &&
        this.data.list.length >= this.$store.state.pageSet
      ) {
        this.patientPayInfoPost(this.active)
      }
    },
    //切换
    showClick(active) {
      if (this.error) {
        this.businessHistoryQueryPost(this.active)
        this.patientPayInfoPost(this.active)
        this.error = false
        this.once = false
        this.loading = true
      } else if (this.active == 1 && this.once) {
        this.once = false
        this.businessHistoryQueryPost(this.active)
      }
      this.$nextTick(() => {
        this.$refs.scroll[active].scrollTo(0, 0, 0)
        this.$refs.scroll[active].refresh()
      })
    },
    //缴费明细
    async detailed(ative, { charge_id }) {
      if (ative == 0) {
        this.$router.push({ path: '/payment-details', query: { charge_id } })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.region-scroll {
  height: calc(100vh - 6.75rem);
  width: 100vw;
  overflow: hidden;
}

.pay {
  .van-tabs {
    .line {
      display: flex;
      justify-content: space-between;
      // padding: 1rem 0;
      // border-top: 1px solid @lineColor;
      // overflow: hidden;
      .handle-button {
        // float: right;
        margin-right: 1rem;
        height: 2rem;
        width: 4rem;
        border-color: @darkColor;
        background-color: @darkColor;
        border-radius: 2rem;
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      background-color: @cardColor;
      margin-bottom: 1rem;
      padding: 1rem 1rem;
      .card-text {
        display: flex;
        justify-content: space-between;
        line-height: 2rem;
        .time {
          color: rgb(141, 143, 161);
        }
        .detailed {
          color: @lightColor;
        }
      }
    }
  }
}
</style>
